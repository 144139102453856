export const state = () => ({
    data: {},
    fancyData: {},
    loading: true,
    loadingPremiumFancy: true
})

export const mutations = {
    set_data(state, data) {
        state.data = data
        const marketIds = data
            .map(
                (match) =>
                    match.oddsType.toLowerCase() === "odds" &&
                    "AllSocket_Market_" + match.marketId
            )
            .filter(Boolean)
        if (marketIds.length > 0) {
            this.commit("socketBookmakerMarket/SET_IDS", marketIds)
        }
        // this.commit(
        //   'bookmakerMarket/filter_premium',
        //   this.state.betBooks.clearBookmakerData
        // )
    },
    set_data_fancy(state, data) {
        state.fancyData = data
        this.commit(
            "bookmakerMarket/filter_fancy",
            this.state.betBooks.clearFancyBookData
        )
    },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_loading_fancy(state, data) {
        state.loadingPremiumFancy = data
    },
    reset(state) {
        state.fancyData = []
    },
    filter_fancy(state, data) {
        let bookIds = []
        if (data && data.length > 0) {
            bookIds = data.map((item) => item.marketId)
        }
        const fancy = state.fancyData
        fancy.map((item) => {
            if (bookIds.includes(item.marketId)) {
                item.fancySort = 0
            } else {
                item.fancySort = 1
            }
            return item
        })

        state.fancy = fancy.sort((a, b) => {
            if (a.fancySort > b.fancySort) return 1
            if (a.fancySort < b.fancySort) return -1
            return 0
        })
    }
}

export const actions = {
    async get({ commit }, { betfairId, socketData }) {
        commit("set_loading", true)
        let data = {}
        try {
            if (socketData) {
                commit("set_loading", false)
                commit("set_data", socketData)
            } else {
                data = await this.$axios({
                    method: "post",
                    baseURL: process.env.PREMIUM_BOOKMAKER_URL,
                    url: "/markets/getMarketAndRunner",
                    data: {
                        betfairId
                    }
                })
                if (
                    data &&
                    data.data &&
                    data.data.data &&
                    Array.isArray(data.data.data) &&
                    data.data.data.length
                ) {
                    commit("set_data", data.data.data)
                } else {
                    commit("set_data", [])
                }
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
        }
    },
    async getFancy({ commit }, { betfairId, socketData, loading }) {
        commit("set_loading_fancy", loading)
        let data = {}
        try {
            if (socketData) {
                commit("set_loading", false)
                commit("set_data_fancy", socketData)
            } else {
                data = await this.$axios({
                    method: "post",
                    baseURL: process.env.PREMIUM_BOOKMAKER_URL,
                    url: "/markets/getAllFancy",
                    data: {
                        betfairId
                    }
                })
                if (
                    data &&
                    data.data &&
                    data.data.data &&
                    Array.isArray(data.data.data) &&
                    data.data.data.length
                ) {
                    commit("set_data_fancy", data.data.data)
                } else {
                    commit("set_data_fancy", [])
                }
            }
            commit("set_loading_fancy", false)
        } catch (error) {
            commit("set_error", error)
        }
    }
}
