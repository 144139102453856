export const state = () => ({
    data: [],
    loading: false,
    error: {}
})

export const mutations = {
    set_data(state, data) {
        if (data.length) {
            state.data = data.sort((a, b) => a.order - b.order)
        }
    },
    set_loading(state, data) {
        state.loading = data
    },
    set_error(state, data) {
        state.error = data
    }
}

export const actions = {
    async getSlider({ commit }) {
        try {
            commit("set_loading", true)
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.CONFIG_V2_API_URL,
                url: "Banner/getBanner",
                data: { page: 1, keyword: "" }
            })
            if (response.data.value) {
                commit("set_data", response.data.data)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
