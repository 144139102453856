import find from "lodash/find"
import isEmpty from "lodash/isEmpty"

export const state = () => ({
    data: [],
    loading: false,
    error: "",
    activeGame: "",
    competionData: [],
    eventCompetionData: [],
    premiumSportsbookData: []
})

export const mutations = {
    set_data(state, data) {
        data.map((d) => {
            switch (d.name.toLowerCase()) {
                case "cricket":
                    d.sort = 0
                    break
                case "soccer":
                    d.sort = 1
                    break
                case "tennis":
                    d.sort = 2
                    break
                case "Live Casino":
                    d.sort = 3
                    break
                case "Live Card":
                    d.sort = 4
                    break
                default:
                    if (d.allMarketCount > 0) {
                        d.sort = 5
                        break
                    }
                    d.sort = 6
                    break
            }
            return d
        })
        data.sort((a, b) => a.sort - b.sort)
        state.data = data

        // state.data = remove(state.data, function (singleGame) {
        //   return !singleGame.disabledEvent
        // })

        const newGame = find(state.data, function (singleGame) {
            return singleGame.allMarketCount > 0
        })
        if (!isEmpty(newGame)) {
            state.activeGame = newGame.name
        } else {
            state.activeGame = "Cricket"
        }
    },
    set_data_premium_sportsbook(state, data) {
        state.premiumSportsbookData = data
    },
    set_competition_data(state, data) {
        state.competionData = data
    },
    set_event_competition_data(state, data) {
        state.eventCompetionData = data
    },

    empty_data(state, data) {
        state.competionData = []
        state.eventCompetionData = []
        // state.data = []
    },

    // set_data_event_access(state, data) {
    //   state.eventData = data
    // },
    set_error(state, data) {
        state.error = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    reset_data(state) {
        state.data = []
    }
}

export const actions = {
    async get({ commit }) {
        const reqData = {}
        if (this.$auth.loggedIn) {
            reqData._accessToken = this.$auth.getToken("customLocal")
        }
        try {
            commit("set_loading", true)
            // commit('reset_data')
            const { data } = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                data: reqData,
                url: "Category/getCategoriesForNavigation"
            })
            commit("set_data", data.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },
    async getPremiumSportsbook({ commit }) {
        const reqData = {}
        if (this.$auth.loggedIn) {
            reqData._accessToken = this.$auth.getToken("customLocal")
        }
        try {
            commit("set_loading", true)
            // commit('reset_data')
            const { data } = await this.$axios({
                method: "post",
                baseURL:
                    process.env.APP_TITLE === "SuperWin" &&
                    process.env.fairplayEnvironment === "PROD"
                        ? process.env.CONFIG_V2_API_URL_WIN
                        : process.env.CONFIG_V2_API_URL,
                data: reqData,
                url: "betbymenu/getAllMenu"
            })
            commit("set_data_premium_sportsbook", data.data)
            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    // api call for get competition of left side menu
    async getCompetition({ commit }, payload) {
        try {
            commit("set_loading", true)
            // commit('reset_data')
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                data: {
                    _accessToken: this.$auth.getToken("customLocal"),
                    name: payload,
                    eventTypeId: "1"
                },
                url: "Category/getCompetitions"
            })

            if (response && response.data && response.data.value) {
                commit("set_competition_data", response.data.data)
            }

            commit("set_loading", false)
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    },

    // api call for get event of cpmpition left side menu
    async getEventCompetition({ commit }, payload) {
        try {
            // commit('reset_data')
            const response = await this.$axios({
                method: "post",
                baseURL: process.env.SPORTSBOOK_API_URL,
                data: {
                    _accessToken: this.$auth.getToken("customLocal"),
                    _id: payload
                },
                url: "Category/getEventsOfCompitition "
            })

            if (response && response.data && response.data.value) {
                commit("set_event_competition_data", response.data.data)
            }
        } catch (error) {
            commit("set_error", error)
            commit("set_loading", false)
        }
    }
}
