import _ from "lodash"
import map from "lodash/map"
import orderBy from "lodash/orderBy"
import fbService from "@/services/fbApiService"
export const state = () => ({
    loading: false,
    showScore: true,
    statsOddLoading: false,
    marketTab: false,
    marketData: {},
    poolDetailsData: {},
    matchedBetPremium: [],
    matchedBetPremiumRaw: [],
    totalNoCountPremium: 0,
    betsDatasPremium: [
        {
            name: "Premium SportsBook",
            matchedPremiumSportsBookBets: []
        }
    ],
    betsDatas: [
        {
            name: "Unmatched Bets",
            unMatchedbets: []
        },
        {
            name: "Matched Bets",
            matchedBets: []
        }
    ],
    fantasyBetsDatas: [
        // {
        //     name: "Unmatched Bets",
        //     unMatchedbets: []
        // },
        {
            name: "Matched Bets",
            matchedBets: []
        }
    ],
    fantasyHideUnMatch: false,
    fantasyHideMatch: false,
    predictions: [
        {
            // MY PREDICT WINNER
            title: "MY WINNER",
            allPredictions: []
        },
        {
            // MY PREDICT FANCY
            title: "MY FANCY",
            allPredictions: []
        }
    ],
    markets: [
        {
            name: "Match Odds",
            runners: []
        },
        { name: "Fancy", runners: [] }
    ],
    // allSocketFunctions: {},
    allQuiz: [],
    userPointsAfterBet: 0,
    totalPointsGiven: 0,
    netExposure: 0,
    leaderBoardPoints: 0,
    allFancyMarkets: [],
    allFancyBook: [],
    leaderBoard: [],
    fetchContestName: [],
    userLeaderBoard: {},
    matchOddsBook: [],
    matchIds: [],
    from: [],
    to: [],
    socketId: undefined,
    unExecutedProfit: {},
    // unExecutedProfit: {},
    getStats: {},
    userStats: [],
    // predictData: [],
    oddsData: [],
    netMarketTotal: 0,
    playerDetails: [],
    LeaderBoardLoading: false,
    FetchContestLoading: false,
    page: 1,
    totalCount: 0,
    betsCount: 0,
    premium: [],
    minMaxMarket: undefined,
    minMaxMarketPremium: undefined,
    minMaxMarketLoading: false
})
export const mutations = {
    // set_Bets_data_premium(state, betDataPremium) {
    //     state.matchedBetPremium = betDataPremium.data
    // },
    // empty_matches(state) {
    //     state.betsDatas[0].unMatchedbets = []
    //     state.betsDatas[1].matchedBets = []
    // },
    set_Bets_data_premium_raw(state, betDataPremiumRaw) {
        state.matchedBetPremiumRaw = betDataPremiumRaw.outputArray
        state.totalNoCountPremium = betDataPremiumRaw.totalCount
    },
    reset_Bets_data_premium_raw(state) {
        state.matchedBetPremiumRaw = []
        state.totalNoCountPremium = 0
    },
    set_Bets_data(state, betData) {
        if (betData && betData.length > 0) {
            map(betData, (singleData) => {
                if (singleData.status === "EXECUTION_COMPLETE") {
                    state.fantasyBetsDatas[0].matchedBets.push(singleData)
                    state.fantasyBetsDatas[0].matchedBets = orderBy(
                        state.fantasyBetsDatas[0].matchedBets,
                        ["placedDate"],
                        ["desc"]
                    )
                }
                // else {
                //     state.fantasyBetsDatas[0].unMatchedbets.push(singleData)
                //     state.fantasyBetsDatas[1].unMatchedBets = orderBy(
                //         state.fantasyBetsDatas[1].unMatchedBets,
                //         ["placedDate"],
                //         ["desc"]
                //     )
                // }
            })
            map(betData, (matchOddsData) => {
                if (matchOddsData.marketName === "Match Odds") {
                    state.markets[0].runners.push(matchOddsData)
                } else {
                    state.markets[1].runners.push(matchOddsData)
                    if (
                        state.markets[1] &&
                        state.markets[1].runners &&
                        state.markets[1].runners.length
                    ) {
                        state.betsCount = state.markets[1].runners.length
                    } else {
                        state.betsCount = 0
                    }
                }
            })
        } else {
            state.fantasyBetsDatas[0].matchedBets = []
            state.markets[0].runners = []
            state.markets[1].runners = []
            state.betsCount = 0
            // state.fantasyBetsDatas[0].unMatchedbets = []
        }

        // if (state.fantasyBetsDatas[0].fantasyBetsDatas.length === 0) {
        //     state.fantasyHideUnMatch = true
        // } else {
        //     state.fantasyHideUnMatch = false
        // }

        if (state.fantasyBetsDatas[0].matchedBets.length === 0) {
            state.fantasyHideMatch = true
        } else {
            state.fantasyHideMatch = false
        }
    },
    reset_set_Bets_data(state) {
        state.fantasyBetsDatas[0].matchedBets = []
        state.markets[0].runners = []
        state.markets[1].runners = []
        // state.fantasyBetsDatas[0].unMatchedbets = []
    },
    setPage(state) {
        state.page = state.page + 1
    },
    backPage(state) {
        state.page = state.page - 1
    },
    resetPage(state) {
        state.page = 1
    },
    emptyPage(state) {
        state.page = 1
    },
    bookForMatchOdds(state, data) {
        const newData = JSON.parse(JSON.stringify(data))
        const newExecutedProfit = JSON.parse(
            JSON.stringify(state.unExecutedProfit)
        )
        newExecutedProfit[newData.horse] = newData.amount
        state.unExecutedProfit = newExecutedProfit
    },
    setMarketData(state, data) {
        // data.bookMakerCustom = false
        // data.eventType.isMatchOdds = true
        // data.eventType.eventTypeId = "4"

        // if (data && data.runners) {
        //     _.each(data.runners, (singleRunner) => {
        //         if (singleRunner && singleRunner.batb) {
        //             singleRunner.batb.forEach((backRate) => {
        //                 if (
        //                     backRate[0] === 0 &&
        //                     singleRunner.singleBackRate !== backRate[1]
        //                 ) {
        //                     singleRunner.singleBackRate = backRate[1]
        //                     singleRunner.singleBackSize = backRate[2]
        //                 }
        //                 return singleRunner
        //             })
        //         }
        //     })
        // }

        if (data && data.data && data.data.length) {
            const newData = data.data.filter((m) => m.name === "Match Odds")
            state.marketData = newData[0]
        }
    },
    setPoolDetailsForInnerMatch(state, data) {
        state.poolDetailsData = data
    },
    initializeBook(state) {
        state.unExecutedProfit = {}
    },
    ratesChange(state, data) {
        state.marketData.runners = state.marketData.runners.map((runner) => {
            _.forEach(data.rates, function (rate) {
                if (_.toString(runner.betfairId) === _.toString(rate.id)) {
                    _.forEach(rate.batb, function (backRate) {
                        if (
                            backRate[0] === 0 &&
                            runner.singleBackRate !== backRate[1]
                        ) {
                            runner.singleBackRate = backRate[1]
                            runner.singleBackSize = backRate[2]
                        }
                        return runner
                    })
                }
            })
            return runner
        })
        state.marketData.betfairStatus = data.betfairStatus
        state.inPlayStatus = data.inPlayStatus
        state.isAvailable = data.isAvailable
    },
    setQuizOutput(state, data) {
        state.allQuiz = data
    },
    setMarketBalance(state, data) {
        state.userPointsAfterBet = data.balanceUp
        state.totalPointsGiven = data.totalPointsGiven
        state.netExposure = data.betNetExposure
        state.leaderBoardPoints = data.totalPointsGiven + data.accountDetail
    },
    setAllFancyMarkets(state, data) {
        state.allFancyMarkets = data.filter(
            (item) =>
                item.status === "Enable" || item.status === "resultDeclared"
        )
        this.commit(
            "matchInner/filter_fancy",
            this.state.betBooksFantasy.cleanFantasyBook
        )
    },
    set_single_fancy(state, data) {
        const fancyData = state.allFancyMarkets
        const index = fancyData.findIndex((el) => el._id === data._id)
        if (index >= 0) {
            fancyData[index] = data
            state.allFancyMarkets = fancyData
        }
    },
    filter_fancy(state, data) {
        let bookIds = []
        if (data && data.length > 0) {
            bookIds = data.map((item) => item.marketId)
        }
        const fancy = state.allFancyMarkets
        fancy.map((item) => {
            if (bookIds.includes(item.betfairId)) {
                item.fancySort = 0
            } else {
                item.fancySort = 1
            }
            return item
        })

        state.allFancyMarkets = fancy.sort((a, b) => {
            if (a.fancySort > b.fancySort) return 1
            if (a.fancySort < b.fancySort) return -1
            return 0
        })
    },
    initializeFancyArray(state, data) {
        state.allFancyMarkets = data
    },
    setAllFancyBook(state, data) {
        _.each(data, (singleBook) => {
            state.allFancyBook.push(
                (state.allFancyBook[singleBook.marketId] =
                    singleBook.netExposure)
            )
        })
    },
    initializeMatchInner(state) {
        state.marketData = {}
        state.allSocketFunctions = {}
        state.allQuiz = []
        state.userPointsAfterBet = 0
        state.totalPointsGiven = 0
        state.netExposure = 0
        state.leaderBoardPoints = 0
        state.allFancyMarkets = []
        state.allFancyBook = []
        state.matchOddsBook = []
        state.predictions[0].allPredictions = []
        state.predictions[1].allPredictions = []
    },
    initializeLeaderBoard(state) {
        state.leaderBoard = []
        state.totalCount = 0
    },
    setLeaderBoard(state, data) {
        state.leaderBoard = state.leaderBoard.concat(data)
        const duplicateIds = []
        state.leaderBoard.forEach((obj) => {
            if (state.leaderBoard.includes(obj.userId) === -1) {
                state.leaderBoard.push(obj.userId)
            } else {
                duplicateIds.push(obj.userId)
            }
        })
        if (duplicateIds.length > 0) {
            duplicateIds.forEach((userId) => {
                const indexesToRemove = []
                state.leaderBoard.forEach((obj, index) => {
                    if (obj.userId === userId) {
                        indexesToRemove.push(index)
                    }
                })
                for (let i = indexesToRemove.length - 1; i > 0; i--) {
                    state.leaderBoard.splice(indexesToRemove[i], 1)
                }
            })
        }
    },
    setFetchContestName(state, data) {
        state.fetchContestName = data
    },
    setUserLeaderBoard(state, data) {
        state.userLeaderBoard = data
    },
    setFetchContestName(state, data) {
        state.fetchContestName = data
    },
    showScore(state, data) {
        state.showScore = data
    },
    setMatchOddsBook(state, data) {
        state.matchOddsBook = data
    },
    setStatsData(state, data) {
        state.getStats = data
    },
    setLeaderBoardLoading(state, data) {
        state.LeaderBoardLoading = data
    },
    setFetchContestLoading(state, data) {
        state.FetchContestLoading = data
    },
    set_loading_betOdd(state, data) {
        state.statsOddLoading = data
    },
    setUserData(state, data) {
        state.userStats = data
    },
    set_fancy(state, data) {
        state.allFancyMarkets = data.filter(
            (item) =>
                item.status === "Enable" || item.status === "resultDeclared"
        )
    },
    // setPredictData(state, data) {
    //     const groupedData = _.groupBy(data, (obj) => {
    //         return obj.marketName
    //     })
    //     // _.each(groupedData['Match Odds'], (singleData) => {
    //     //   singleData.selectionName = singleData.selectionName
    //     // })
    //     state.predictions[0].allPredictions = groupedData["Match Odds"]
    //     state.predictions[1].allPredictions = groupedData["fancy-market-custom"]
    // },
    setOddsData(state, data) {
        let type1 = 0
        let type2 = 0
        if (data && data[0] && data[0].bets) {
            _.each(data[0].bets, function (n) {
                if (n.type === "BACK" || n.type === "YES") {
                    if (n.betWinStatus === "WON") {
                        n.amt =
                            ((n.averagePriceMatched !== 0
                                ? n.averagePriceMatched
                                : n.betRate) -
                                1) *
                            n.stake
                    } else if (
                        n.betWinStatus === "VOID" ||
                        n.betWinStatus === "ABANDONED" ||
                        n.betWinStatus === "INVALID_BET"
                    ) {
                        n.amt = 0
                    } else {
                        n.amt = n.stake * -1
                    }

                    type1 = type1 + n.amt
                } else if (n.type === "LAY" || n.type === "NO") {
                    if (n.betWinStatus === "WON") {
                        n.amt = n.stake
                    } else if (
                        n.betWinStatus === "VOID" ||
                        n.betWinStatus === "ABANDONED" ||
                        n.betWinStatus === "INVALID_BET"
                    ) {
                        n.amt = 0
                    } else {
                        n.amt =
                            ((n.averagePriceMatched !== 0
                                ? n.averagePriceMatched
                                : n.betRate) -
                                1) *
                            n.stake *
                            -1
                    }
                    type2 = type2 + n.amt
                }
            })
            state.oddsData = data
            state.netMarketTotal = type1 + type2
        }
    },
    clearAllPLDetails(state) {
        state.oddsData = []
        state.netMarketTotal = 0
    },
    clearAllStats(state) {
        state.userStats = []
    },
    setPlayerDetails(state, data) {
        state.playerDetails = data
    },
    set_loading(state, data) {
        state.loading = data
    },
    increaseAllContestPageCount(state) {
        state.page = ++state.page
    },
    setTotalCount(state, data) {
        state.totalCount = data
    },
    setMarketTabActive(state, data) {
        state.marketTab = data
    },
    set_premium(state, data) {
        state.premium = data
        this.commit(
            "matchInner/filter_premium",
            this.state.betBooksFantasy.clearPremiumData
        )
    },
    clear_premium(state) {
        state.premium = []
    },
    filter_premium(state, data) {
        let bookIds = []
        if (data && data.length > 0) {
            bookIds = data.map((item) => item.marketUniqueId)
        }
        const premium = state.premium
        premium.map((item) => {
            if (bookIds.includes(item._id)) {
                item.premiumSort = 0
            } else {
                item.premiumSort = 1
            }
            return item
        })

        state.premium = premium.sort((a, b) => {
            if (a.premiumSort > b.premiumSort) return 1
            if (a.premiumSort < b.premiumSort) return -1
            return 0
        })
    },
    set_min_max_market(state, data) {
        state.minMaxMarket = data
    },
    set_min_max_market_premium(state, data) {
        state.minMaxMarketPremium = data
    },
    set_min_max_market_loading(state, data) {
        state.minMaxMarketLoading = data
    }
}
export const actions = {
    // async marketForMatchInner({ commit }, data) {
    //     const marketOutput = await fbService.apiCallWithHeader(
    //         "/Category/getMarketForMatchInner",
    //         data
    //     )

    //     console.log("marketForMatchInner", marketOutput)
    //     if (
    //         marketOutput &&
    //         marketOutput.status === 200 &&
    //         marketOutput.data &&
    //         marketOutput.data.betfairId
    //     ) {
    //         commit("socketRate/SET_IDS", [marketOutput.data.betfairId], {
    //             root: true
    //         })
    //         commit("setMarketData", marketOutput.data)
    //         commit("socket/addMatchOddsId", marketOutput.data.betfairId, {
    //             root: true
    //         })
    //     }
    // },
    // async getMyCurrentPremiumBet({ commit, getters }, loading = true) {
    //     if (loading) commit("empty_matches")
    //     try {
    //         if (getters.cancelflag === false) {
    //             commit("set_loading", loading)
    //         }
    //         const response = await this.$axios({
    //             method: "post",
    //             baseURL: process.env.PREMIUM_SPORTSBOOK_URL,
    //             url: "bet/getuserbets",
    //             data: { _accessToken: this.$auth.getToken("customLocal") }
    //         })
    //         if (response.data.value) {
    //             commit("set_Bets_data_premium", response.data)
    //         }
    //         commit("set_loading", false)
    //     } catch (error) {
    //         commit("set_error", error)
    //         commit("set_loading", false)
    //     }
    // },
    async getMinMaxMarketPremium({ commit }, { marketId, loading }) {
        try {
            commit("set_min_max_market_loading", loading)
            const response = await this.$axios({
                method: "get",
                baseURL: process.env.UOF_SPORTSRADAR_SERVICE_URL_FANTASY,
                url: "/Market/getMinMaxMarketForPlayer/Cricket/" + marketId
            })
            // console.log(response.data.data.outputObject)
            if (
                response &&
                response.data &&
                response.data &&
                response.data.data
            ) {
                commit(
                    "set_min_max_market_premium",
                    response.data.data.outputObject
                )
                commit("set_min_max_market_loading", false)
            } else {
                commit("set_min_max_market_premium", response.data.data)
                throw response.data
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            commit("set_min_max_market_loading", false)
        }
    },
    async getMyCurrentPremiumRawBet(
        { commit, getters },
        { loading = true, dataSet }
    ) {
        if (this.state.user && this.state.user.fantasyConfig) {
            // console.log("getters", dataSet, loading)
            // if (loading) commit("empty_matches")
            try {
                if (getters.cancelflag === false) {
                    commit("set_loading", loading)
                }
                commit("set_Bets_data_premium_raw", {
                    outputArray: [],
                    totalCount: 0
                })
                const response = await fbService.apiCallWithHeader(
                    "/v2/getUofbets",
                    { marketId: dataSet }
                )
                if (
                    response &&
                    response.data &&
                    response.data.success &&
                    response.data.data
                ) {
                    commit("set_Bets_data_premium_raw", response.data.data)
                }
                commit("set_loading", false)
            } catch (error) {
                commit("set_error", error)
                commit("set_loading", false)
            }
        }
    },
    async getPoolDetailsForInnerMatch({ commit, state }, data) {
        // if (state.loading) {
        //     return
        // }
        commit("set_loading", true)
        try {
            const jsonData = {
                contestId: data.contestId
            }
            const dataOutput = await fbService.apiCallWithHeader(
                "/v2/getPoolDetailsForInnerMatch",
                jsonData
            )
            if (
                dataOutput &&
                dataOutput.data &&
                dataOutput.data.status === 200 &&
                dataOutput.data.data &&
                dataOutput.data.data
            ) {
                commit("setPoolDetailsForInnerMatch", dataOutput.data.data)
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_loading", false)
        }
    },
    async getMarketsById({ commit, state, rootState }, data) {
        let userId
        let _accessToken
        // let difference = arrA.filter(x => !arrB.includes(x));
        if (this.$auth.loggedIn) {
            userId = this.$auth.user._id
            _accessToken = this.$auth.getToken("customLocal")
        }
        const json = {
            game: "Cricket",
            _id: userId,
            _accessToken,
            parentId: data.parentId,
            accessLevel: "Player"
        }
        const marketOutput = await fbService.apiCallWithHeaderfantasy(
            "/Category/getMarketIds",
            json
        )
        if (
            marketOutput &&
            marketOutput.status === 200 &&
            marketOutput.data &&
            marketOutput.data.data &&
            marketOutput.data.data[0]
        ) {
            commit(
                "socketRate/SET_IDS",
                [marketOutput.data.data[0].betfairId],
                {
                    root: true
                }
            )
            marketOutput.data.data[0].runners =
                marketOutput.data.data[0].runners.map((singleRunner) => {
                    const foundData =
                        rootState?.prizeBook?.categoryData?.runners?.find(
                            (singleRun) => {
                                return (
                                    singleRun.betfairId ===
                                    singleRunner.betfairId
                                )
                            }
                        )
                    if (foundData) {
                        singleRunner.avtarUrl = foundData.avtarUrl
                    }
                    return singleRunner
                })
            marketOutput.data.data[0].team1 =
                rootState.prizeBook.categoryData.team1
            marketOutput.data.data[0].team2 =
                rootState.prizeBook.categoryData.team2
            commit("setMarketData", marketOutput.data)
            // commit(
            //     "socket/addMatchOddsId",
            //     marketOutput.data.data[0].betfairId,
            //     {
            //         root: true
            //     }
            // )
        }
    },
    async getAllQuiz({ commit }, data) {
        const quizOutput = await fbService.apiCallWithHeader(
            "/QuizAnswerByUser/getQuizPerUserByMarketId",
            data
        )
        if (
            quizOutput &&
            quizOutput.data &&
            quizOutput.data.status &&
            quizOutput.data.status === 200 &&
            quizOutput.data.data
        ) {
            commit("setQuizOutput", quizOutput.data.data)
        } else {
            commit("setQuizOutput", [])
        }
    },
    async getMarketBalance({ commit }, data) {
        const marketBalanceData = await fbService.apiCallWithHeader(
            "/v2/getUserBalancePerMarket",
            data
        )
        if (
            marketBalanceData &&
            marketBalanceData.status &&
            marketBalanceData.status === 200 &&
            marketBalanceData.data &&
            marketBalanceData.data.data
        ) {
            commit("setMarketBalance", marketBalanceData.data.data)
        }
    },
    async getAllFancyMarkets({ commit }, data) {
        const allMarketData = await fbService.apiCallForFancyMarket(
            "/SubMarket/getAll",
            data
        )
        if (
            allMarketData &&
            allMarketData.data &&
            Array.isArray(allMarketData.data) &&
            allMarketData.data.length
        ) {
            // allMarketData.data.map((match) => match.betfairId)
            commit("setAllFancyMarkets", allMarketData.data)
            // commit("socket/addFancyMatchId", matchIds, {
            //     root: true
            // })
        } else {
            commit("initializeFancyArray", [])
        }
    },
    async getAllFancyBooks({ commit }, data) {
        const allBooksData = await fbService.apiCallWithHeader(
            "/v2/getBookForCustomFancy",
            data
        )
        if (
            allBooksData &&
            allBooksData.data &&
            allBooksData.data.status === 200 &&
            allBooksData.data.data &&
            Array.isArray(allBooksData.data.data) &&
            allBooksData.data.data.length
        ) {
            commit("setAllFancyBook", allBooksData.data.data)
        }
    },
    async getLeaderBoard({ commit, state }, data) {
        if (state.LeaderBoardLoading) {
            return
        }
        try {
            commit("setLeaderBoardLoading", true)
            // data.limit = 3
            const leaderBoardData = await fbService.apiCallWithHeader2(
                "/v2/getLeaderBoard",
                data
            )
            if (
                leaderBoardData &&
                leaderBoardData.status === 200 &&
                leaderBoardData.data &&
                leaderBoardData.data.data
            ) {
                if (
                    leaderBoardData.data.data.leaderData &&
                    leaderBoardData.data.data.leaderBoardCount
                ) {
                    commit(
                        "setLeaderBoard",
                        leaderBoardData.data.data.leaderData
                    )
                    commit(
                        "setTotalCount",
                        leaderBoardData.data.data.leaderBoardCount
                    )
                }
                if (leaderBoardData.data.data.userLeaderBoard) {
                    commit(
                        "setUserLeaderBoard",
                        leaderBoardData.data.data.userLeaderBoard[0]
                    )
                }
            }
            commit("setLeaderBoardLoading", false)
        } catch (error) {
            commit("setLeaderBoardLoading", false)
        }
    },
    async fetchContest({ commit, state }, data) {
        if (state.FetchContestLoading) {
            return
        }
        try {
            commit("setFetchContestLoading", true)
            const fetchContestData = await fbService.apiCallWithHeader2(
                "/v2/fetchcontestname",
                data
            )
            if (
                fetchContestData &&
                fetchContestData.status === 200 &&
                fetchContestData.data &&
                fetchContestData.data.data &&
                fetchContestData.data.data.length
            ) {
                commit("setFetchContestName", fetchContestData.data.data)
            }
            commit("setFetchContestLoading", false)
        } catch (error) {
            commit("setFetchContestLoading", false)
        }
    },
    async getMatchOddsBook({ commit }, data) {
        const booksOutput = await fbService.apiCallWithHeader(
            "/v2/Bet/getBookPlayer",
            data
        )
        if (
            booksOutput &&
            booksOutput.data &&
            booksOutput.data.data &&
            booksOutput.data.data.runners
        ) {
            commit("setMatchOddsBook", booksOutput.data.data.runners)
        }
    },
    async getBetStatsByMarketIdPerUser({ commit }, data) {
        commit("set_loading", true)
        commit("setStatsData", [])
        const getStatsData = await fbService.apiCallWithHeader(
            "/v2/getBetStatsByMarketIdPerUser",
            data
        )
        if (
            getStatsData &&
            getStatsData.data &&
            Array.isArray(getStatsData.data) &&
            getStatsData.data.length
        ) {
            commit("setStatsData", getStatsData.data)
        }
        commit("set_loading", false)
    },
    async getAllStats({ commit }, data) {
        try {
            commit("set_loading_betOdd", true)
            commit("setUserData", [])
            const getUserStat = await fbService.apiCallWithHeader(
                "/QuizAnswerByUser/quizStatsPerUser",
                data
            )
            if (getUserStat && getUserStat.data) {
                commit("setUserData", getUserStat.data)
            }
            commit("set_loading_betOdd", false)
        } catch (error) {
            commit("set_loading_betOdd", false)
        }
    },
    async getPredict({ commit }, data) {
        try {
            commit("set_loading", true)
            const getPredictData = await fbService.apiCallWithHeader(
                "/v2/Bet/getMyCurrentBetStatus",
                data
            )
            commit("reset_set_Bets_data")
            if (getPredictData && getPredictData.data) {
                // commit("setPredictData", getPredictData.data.data)
                commit("set_Bets_data", getPredictData.data.data)
            } else {
                // commit("setPredictData", [])
                commit("set_Bets_data", [])
            }
            commit("set_loading", false)
        } catch (error) {
            commit("set_loading", false)
        }
    },
    async getAllPLDetails({ commit }, data) {
        try {
            commit("set_loading_betOdd", true)
            const getOddsData = await fbService.apiCallWithHeader(
                "/v2/getBetPLPerEventNewForMatchOdds",
                data
            )
            if (
                getOddsData &&
                getOddsData.status === 200 &&
                getOddsData.data &&
                getOddsData.data.data
            ) {
                commit("setOddsData", getOddsData.data.data)
            } else {
                commit("setOddsData", [])
            }
            commit("set_loading_betOdd", false)
        } catch (error) {
            commit("set_loading_betOdd", false)
        }
    },
    async getOneLeaderBoardDetails({ commit }, data) {
        const outputData = await fbService.apiCallWithHeader(
            "/v2/getPlayerPointAndRankByLeaderboardId",
            data
        )
        if (outputData && outputData.data && outputData.data.data) {
            commit("setPlayerDetails", outputData.data.data)
        } else {
            commit("setPlayerDetails", {})
        }
    },
    async getPlayerStats({ commit }, data) {
        const getStatsData = await fbService.apiCallWithHeader(
            "/pool/getBetStatsPerPlayer",
            data
        )
        if (
            getStatsData &&
            getStatsData.data &&
            Array.isArray(getStatsData.data) &&
            getStatsData.data.length
        ) {
            commit("setStatsData", getStatsData.data)
        } else {
            commit("setStatsData", [])
        }
    },
    async getAllUOFMarketsByEventId({ commit }, { payload, socketData }) {
        try {
            let data = {}
            if (socketData) {
                commit("set_premium", socketData)
            } else if (!socketData && payload) {
                data = await this.$axios({
                    method: "get",
                    baseURL: process.env.UOF_SPORTSRADAR_SERVICE_URL_FANTASY,
                    url: "/Market/getAllUOFMarketsByEventId/" + payload
                })
                if (
                    data &&
                    data.data &&
                    data.data.data &&
                    data.data.data.outputArray
                ) {
                    commit("set_premium", data.data.data.outputArray)
                } else {
                    commit("set_premium", [])
                }
            } else {
                data = []
                commit("set_premium", [])
            }
        } catch (error) {}
    }
}
